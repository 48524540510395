import styled from 'styled-components';
import welcome_page_image from '../../images/welcome_page_image.png';

export const HeaderContainer = styled.header`
  height: 170px;
  width: 100vw;

  background-image: url(${welcome_page_image});
  background-color: rgb(0, 0, 0);
  background-position: 50% 0%;
  background-repeat: no-repeat;
  background-size: cover;
`;
