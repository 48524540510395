import 'reflect-metadata';
import { Container } from 'inversify';
import { IBusinessFunctionsService } from './common/services/interfaces/IBusinessFunctionsService';
import { BusinessFunctionService } from './common/services/BusinessFunctionService';
import { MockBusinessFunctionService } from './common/services/MockBusinessFunctionService';
import { ICountriesService } from './common/services/interfaces/ICountriesService';
import { MockCountriesFunctionService } from './common/services/MockCountriesService';
import { CountriesService } from './common/services/CountriesService';
import { IStoresService } from './common/services/interfaces/IStoresService';
import { MockStoresService } from './common/services/MockStoresService';
import { StoresService } from './common/services/StoresService';
import { ILocalesService } from './common/services/interfaces/ILocalesService';
import { MockLocalesService } from './common/services/MockLocalesService';
import { LocalesService } from './common/services/LocalesService';
import { ISurveysService } from './common/services/interfaces/ISurveysService';
import { SurveysService } from './common/services/SurveysService';
import { MockSurveysService } from './common/services/MockSurveysService';
import { IUserInterfaceTranslationsService } from './common/services/interfaces/IUserInterfaceTranslationsService';
import { UserInterfaceTranslationsService } from './common/services/UserInterfaceTranslationsService';
import { MockUserInterfaceTranslationsService } from './common/services/MockUserInterfaceTranslationsService';
import {ILocaleDropdownTranslationsService} from "./common/services/interfaces/ILocaleDropdownTranslationsService";
import {MockLocaleDropdownTranslationsService} from "./common/services/MockLocaleDropdownTranslationsService";
import {LocaleDropdownTranslationsService} from "./common/services/LocaleDropdownTranslationsService";

const isMock: boolean | undefined = process.env['REACT_APP_MOCK_DATABASE'] === 'true';

const container = new Container();

if (isMock) {
  container
    .bind<IBusinessFunctionsService>(IBusinessFunctionsService.$)
    .to(MockBusinessFunctionService)
    .inTransientScope();
  container
    .bind<ICountriesService>(ICountriesService.$)
    .to(MockCountriesFunctionService)
    .inTransientScope();
  container.bind<IStoresService>(IStoresService.$).to(MockStoresService).inTransientScope();
  container.bind<ILocalesService>(ILocalesService.$).to(MockLocalesService).inTransientScope();
  container.bind<ISurveysService>(ISurveysService.$).to(MockSurveysService).inTransientScope();
  container
    .bind<IUserInterfaceTranslationsService>(IUserInterfaceTranslationsService.$)
    .to(MockUserInterfaceTranslationsService)
    .inTransientScope();
  container.bind<ILocaleDropdownTranslationsService>(ILocaleDropdownTranslationsService.$).to(MockLocaleDropdownTranslationsService).inTransientScope();
} else {
  container
    .bind<IBusinessFunctionsService>(IBusinessFunctionsService.$)
    .to(BusinessFunctionService)
    .inTransientScope();
  container.bind<ICountriesService>(ICountriesService.$).to(CountriesService).inTransientScope();
  container.bind<IStoresService>(IStoresService.$).to(StoresService).inTransientScope();
  container.bind<ILocalesService>(ILocalesService.$).to(LocalesService).inTransientScope();
  container.bind<ISurveysService>(ISurveysService.$).to(SurveysService).inTransientScope();
  container
    .bind<IUserInterfaceTranslationsService>(IUserInterfaceTranslationsService.$)
    .to(UserInterfaceTranslationsService)
    .inTransientScope();
  container.bind<ILocaleDropdownTranslationsService>(ILocaleDropdownTranslationsService.$).to(LocaleDropdownTranslationsService).inTransientScope();
}

export { container };
