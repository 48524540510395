import 'reflect-metadata';
import { injectable } from 'inversify';
import { PATHS, requests } from '../utils/fetcher';
import { ILocalesService, LocalesResponse } from './interfaces/ILocalesService';

@injectable()
export class LocalesService implements ILocalesService {
  getLocales(): Promise<LocalesResponse[]> {
    return requests.get(PATHS.LOCALES);
  }

  getLocalesBySurveyId(surveyId: number): Promise<LocalesResponse[]> {
    return requests.get(`${PATHS.LOCALES}/${surveyId}`);
  }
}
