import styled from 'styled-components';
import { FormGroup } from '../FormGroup/FormGroup.styled';

interface Props {
  margin: string;
}

export const Form = styled.form<Props>`
  margin: ${({ margin }) => margin || '0'};

  ${FormGroup} {
    margin-bottom: 25px;
  }

  ${FormGroup}:last-child {
    margin-bottom: 0;
  }
`;
