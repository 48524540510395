import { interfaces } from 'inversify';

export interface CountryResponse {
  businessFunctionCountryId: number;
  name: string;
}

export interface ICountriesService {
  getCountries(locale: string, businessFunctionId: number): Promise<CountryResponse[]>;
}

export namespace ICountriesService {
  export const $: interfaces.ServiceIdentifier<ICountriesService> = Symbol('ICountriesService');
}
