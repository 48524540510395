import { interfaces } from 'inversify';

export interface LocalesResponse {
  locale: string;
}

export interface ILocalesService {
  getLocales(): Promise<LocalesResponse[]>;
  getLocalesBySurveyId(surveyId: number): Promise<LocalesResponse[]>;
}

export namespace ILocalesService {
  export const $: interfaces.ServiceIdentifier<ILocalesService> = Symbol('ILocalesService');
}
