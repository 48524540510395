import 'reflect-metadata';
import { injectable } from 'inversify';
import { IStoresService, RedirectUrlResponse, StoreResponse } from './interfaces/IStoresService';

@injectable()
export class MockStoresService implements IStoresService {
  private storesEn: StoreResponse[] = [
    {
      businessFunctionCountryStoreId: 1,
      name: '108 - Janki',
    },
    {
      businessFunctionCountryStoreId: 2,
      name: '300 - Warsaw',
    },
    {
      businessFunctionCountryStoreId: 3,
      name: '500 - Cracow',
    },
  ];

  private storesPl: StoreResponse[] = [
    {
      businessFunctionCountryStoreId: 1,
      name: '108 - Janki',
    },
    {
      businessFunctionCountryStoreId: 2,
      name: '300 - Warszawa',
    },
    {
      businessFunctionCountryStoreId: 3,
      name: '500 - Kraków',
    },
  ];

  getStores(locale: string, businessFunctionCountryId: number, populationId: number): Promise<StoreResponse[]> {
    if (locale === 'en-gb') {
      return Promise.resolve(this.storesEn);
    } else {
      return Promise.resolve(this.storesPl);
    }
  }

  getRedirectUrl(
    businessFunctionCountryStoreId: number,
    surveyId: number,
    locale: string,
    populationId: number
  ): Promise<RedirectUrlResponse> {
    return Promise.resolve({
      redirectUrl: 'https://survey.cxomni.net/index.php/872839/872839?lang=en&Parameter01=BCD8D683',
    });
  }
}
