import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInterfaceTranslationResponse } from '../services/interfaces/IUserInterfaceTranslationsService';

interface UserInterfaceTranslationsState {
  value: UserInterfaceTranslationResponse;
}

const initialState: UserInterfaceTranslationsState = {
  value: {
    locale: '',
    buttonText: '',
    dropdownLanguageText: '',
    dropdownOneText: '',
    dropdownTwoText: '',
    dropdownThreeText: '',
    selectPlaceholderText: '',
  },
};

export const userInterfaceTranslationsSlice = createSlice({
  name: 'userInterfaceTranslations',
  initialState,
  reducers: {
    setUserInterfaceTranslation: (
      state: any,
      action: PayloadAction<UserInterfaceTranslationResponse>
    ) => {
      state.value = action.payload;
    },
  },
});

export const { setUserInterfaceTranslation } = userInterfaceTranslationsSlice.actions;

export default userInterfaceTranslationsSlice.reducer;
