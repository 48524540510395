import { css } from 'styled-components';
import { IBaseStyles } from '../../common/types';

export const BaseStyles = css<IBaseStyles>`
  color: ${({ theme, fontColor }) => fontColor || theme.color.black};
  font-size: ${({ fontSize, theme }) => fontSize || theme.fontSize.xs};
  font-weight: ${({ fontWeight }) => fontWeight || '500'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  text-transform: ${({ textTransform }) => textTransform || 'none'};
  line-height: ${({ lineHeight }) => lineHeight || 'auto'};
  margin: ${({ margin }) => margin};
  cursor: ${({ cursor }) => cursor || 'default'};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
`;
