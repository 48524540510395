import { FooterContainer } from './Footer.styled';
import { Span } from '../Span/Span.styled';
import { useReduxSelector } from '../../common/hooks/hooks';
import { FlexTemplate } from '../Templates/FlexTemplate/FlexTemplate.styled';
import { MainContainer } from '../../styles/GlobalStyles/GlobalStyle.styled';
import Divider from '../Divider/Divider.styled';

export default function Footer() {
  const survey = useReduxSelector((state) => state.survey);

  return (
    <FooterContainer>
      <MainContainer>
        <FlexTemplate flexDirection="column" gap="40px" alignItems="center">
          <FlexTemplate fontSize="14px">
            <>{survey.value.originText}</>
          </FlexTemplate>
          <Divider />
          <Span fontSize="12px">@ FOUNT Global, Inc. 2024</Span>
        </FlexTemplate>
      </MainContainer>
    </FooterContainer>
  );
}
