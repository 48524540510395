import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCookie, setCookie } from '../utils/cookies';

interface LocaleState {
  value: string;
}

const initialState: LocaleState = {
  value: getCookie('locale') || navigator.language.toLocaleLowerCase(),
};

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    changeLocale: (state: any, action: PayloadAction<string>) => {
      state.value = action.payload;
      setCookie('locale', action.payload, '/');
    },
  },
});

export const { changeLocale } = localeSlice.actions;

export default localeSlice.reducer;
