export const getCookie = (key: string) =>
  document.cookie.split('; ').reduce((total, currentCookie) => {
    const item = currentCookie.split('=');
    const storedKey = item[0];
    const storedValue = item[1];

    return key === storedKey ? decodeURIComponent(storedValue) : total;
  }, '');

export const setCookie = (key: string, value: string, path: string) => {
  document.cookie = `${key}=${value}; path=${path}`;
};
