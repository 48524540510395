import { css } from 'styled-components';
import { IButtonBaseStylesProps } from '../../common/types';
import { BaseStyles } from './BaseStyles.styled';

export const ButtonBaseStyles = css<IButtonBaseStylesProps>`
  ${BaseStyles}
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, fontColor }) => fontColor || theme.color.white};
  border-radius: ${({ borderRadius }) => borderRadius || '9999px'};
  border: ${({ border }) => border || 'none'};
  padding: ${({ padding }) => padding || '12px 24px'};
  margin: ${({ margin }) => margin || '0'};
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.color.brand};
  width: ${({ width }) => width || '200px'};
  height: ${({ height }) => height || 'fit-content'};
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  :hover {
    background-color: ${({ theme, hoverBackgroundColor }) =>
      hoverBackgroundColor || theme.color.brandDarker};
  }

  :disabled {
    background-color: hsl(0, 0%, 95%);
    color: hsl(0, 0%, 50%);
    border: 1px solid hsl(0, 0%, 95%);
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
