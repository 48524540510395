import { interfaces } from 'inversify';

export interface BusinessFunctionResponse {
  businessFunctionId: number;
  name: string;
}

export interface IBusinessFunctionsService {
  getBusinessFunctions(locale: string): Promise<BusinessFunctionResponse[]>;
}

export namespace IBusinessFunctionsService {
  export const $: interfaces.ServiceIdentifier<IBusinessFunctionsService> = Symbol(
    'IBusinessFunctionsService'
  );
}
