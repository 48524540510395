import {
  Main,
  MainTemplateContainer,
} from './components/Templates/MainTemplate/MainTemplate.styled';
import Header from './components/Header/Header';
import { GlobalStyles } from './styles/GlobalStyles/GlobalStyle.styled';
import { ThemeProvider } from 'styled-components';
import Footer from './components/Footer/Footer';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import Home from './routes/Home';
import { theme } from './styles/theme';
import { useCallback, useEffect, useState } from 'react';
import { changeLocale } from './common/redux/localeSlice';
import { ILocalesService } from './common/services/interfaces/ILocalesService';
import { useReduxDispatch, useReduxSelector } from './common/hooks/hooks';
import { useInjection } from 'inversify-react';
import { ISurveysService } from './common/services/interfaces/ISurveysService';
import { setSurveys } from './common/redux/surveysSlice';
import Page404 from './routes/Page404';
import ClipLoader from 'react-spinners/ClipLoader';
import { FlexTemplate } from './components/Templates/FlexTemplate/FlexTemplate.styled';
import { IUserInterfaceTranslationsService } from './common/services/interfaces/IUserInterfaceTranslationsService';
import { setUserInterfaceTranslation } from './common/redux/userInterfaceTranslationsSlice';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const _localesService: ILocalesService = useInjection(ILocalesService.$);
  const _surveysService: ISurveysService = useInjection(ISurveysService.$);
  const _userInterfaceTranslationsService: IUserInterfaceTranslationsService = useInjection(
    IUserInterfaceTranslationsService.$
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const locale = useReduxSelector((state) => state.locale);
  const dispatch = useReduxDispatch();

  useEffect(() => {
    getLocale();
  }, [locale]);

  const getLocale = useCallback(() => {
    _localesService
      .getLocalesBySurveyId(
        Number(searchParams.get(String(process.env['REACT_APP_ID_URL_PARAM_NAME'])))
      )
      .then((response) => {
        setIsLoading(false);
        let apiLocale;

        apiLocale = response.filter((item) => item.locale === locale.value)[0];
        if (!apiLocale)
          apiLocale = response.filter((item) => item.locale === locale.value.split('-')[0])[0];
        if (!apiLocale)
          apiLocale = response.filter((item) =>
            item.locale.startsWith(locale.value.split('-')[0])
          )[0];

        const currentLocale = apiLocale
          ? apiLocale.locale
          : String(process.env['REACT_APP_DEFAULT_LANGUAGE']);
        dispatch(changeLocale(currentLocale));

        return currentLocale;
      })
      .then((currentLocale) => {
        getUserInterfaceTranslations(currentLocale);
        getSurveys(currentLocale);
        setIsLoading(false);
      });
  }, [locale]);

  const getUserInterfaceTranslations = useCallback(
    (currentLocale: string) => {
      _userInterfaceTranslationsService
        .getUserInterfaceTranslation(currentLocale)
        .then((response) => {
          dispatch(
            setUserInterfaceTranslation({
              ...response,
            })
          );
        })
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          navigate('/404');
        });
    },
    [locale]
  );

  const getSurveys = useCallback(
    (currentLocale: string) => {
      _surveysService
        .getSurvey(
          currentLocale,
          Number(searchParams.get(String(process.env['REACT_APP_ID_URL_PARAM_NAME'])))
        )
        .then((response) => {
          dispatch(
            setSurveys({
              ...response,
            })
          );
        })
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          navigate('/404');
        });
    },
    [locale]
  );

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        {!isLoading ? (
          <MainTemplateContainer>
            <Routes>
              <Route path="/" element={<Header />} />
            </Routes>
            <Main>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/404" element={<Page404 />} />
              </Routes>
            </Main>
            <Footer />
          </MainTemplateContainer>
        ) : (
          <FlexTemplate alignItems="center" justifyContent="center" width="100%" height="100%">
            <ClipLoader color={theme.color.brand} loading={isLoading} size={40} />
          </FlexTemplate>
        )}
      </ThemeProvider>
    </>
  );
}

export default App;
