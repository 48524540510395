import { theme } from '../theme';

export const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? theme.color.brand : null,
    '&:hover': { backgroundColor: theme.color.brand },
    color: state.isFocused ? theme.color.white : theme.color.black,
  }),
  control: (provided, state) => ({
    ...provided,
    minWidth: 250,
    border: state.isFocused ? `1px solid ${theme.color.brand}` : provided.border,
    boxShadow: state.isFocused ? `0 0 0 1px ${theme.color.brand}` : 0,
    borderColor: state.isFocused ? theme.color.brand : provided.borderColor,
    '&:hover': {
      borderColor: state.isFocused ? theme.color.brand : provided.borderColor,
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    width: 'auto',
    whiteSpace: 'nowrap',
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  placeholder: (provided, state) => ({
    ...provided,
  }),
};

export const selectStylesLanguage = {
  container: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0 10px 0 0',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? theme.color.brand : null,
    '&:hover': { backgroundColor: theme.color.brand },
    color: state.isFocused ? theme.color.white : theme.color.black,
    '@media (max-width: 500px)': {
      textWrap: 'wrap',
    },
  }),
  control: (provided, state) => ({
    ...provided,
    padding: 0,
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: state.isFocused ? 0 : 0,
    },
  }),
  menuList: (provided, state) => ({
    ...provided,
    minWidth: 'auto',
    padding: 0,
  }),
  menu: (provided, state) => ({
    ...provided,
    width: 'auto',
    '@media (max-width: 500px)': {
      width: 'calc(100vw - 50px)',
    },
    whiteSpace: 'nowrap',
    position: 'absolute',
    right: '-15px',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    textWrap: 'pretty',
  }),
};
