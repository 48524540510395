import 'reflect-metadata';
import { injectable } from 'inversify';
import { ISurveysService, SurveyResponse } from './interfaces/ISurveysService';

@injectable()
export class MockSurveysService implements ISurveysService {
  private surveys: SurveyResponse[] = [
    {
      surveyId: 872839,
      name: 'Survey #1',
      redirectUrl: 'https://survey.cxomni.net',
      redirectParam: 'Parameter01',
      bannerText: 'Your first week',
      greetingText: 'Hej!',
      welcomeText:
        'Thank you for taking the time to tell us about your experience at our company! Please click\n' +
        '&apos;Next&apos; to answer a few short questions, it will take no more than couple of minutes.\n' +
        '<br />' +
        '<br />' +
        'Thanks a lot for your support!</p>\n',
      privacyText:
        'P.S. Participation is optional and no personal data will be collected. Thank you for helping\n' +
        'to improve the experience working here!\n' +
        'If you are interested in reading more how we process the data collected in the survey, you can\n' +
        'refer to our <a href="#">privacy notice</a>.',
      status: 1,
      locale: 'en-gb',
      dropdownOneText: 'Select your business function',
      dropdownTwoText: 'Select your country of work',
      dropdownThreeText: 'Select your store/unit/workplace name',
      originText:
        'These experience question are sent out from Ingka Group, the largest IKEA retailer. Operating IKEA Retail, Ingka Centres and Ingka Investments, read more on ingka.com. Answers are used to improve the experience applying for a job with us and and the experience working in any part of the group.',
      originLogoUrl:
        'https://stingkademographicsprod.blob.core.windows.net/public/logos/011a4704-a7fb-468d-a26d-fe54d9efc5e9.png',
    },
    {
      surveyId: 872839,
      name: 'Ankieta #1',
      redirectUrl: 'https://survey.cxomni.net',
      redirectParam: 'Parameter01',
      bannerText: '',
      greetingText: 'Hej!',
      welcomeText:
        'Dziękuje za poświęcony czas na wypełnienie ankiety! Naciśnij\n' +
        '&apos;Next&apos; ,aby odpowiedziec na kilka krótkich pytań. To nie zajmie długo!\n' +
        'Jeżeli chcesz dowiedzieć się w jaki sposób przetwarzamy dane pochodzące z ankiet, możesz odwiedzić nasze\n' +
        '<a href="#">polityki</a>.',
      privacyText:
        'If you are interested in reading more how we process the data collected in the survey, you can\n' +
        'refer to our <a href="#">privacy notice</a>.',
      status: 1,
      locale: 'pl-pl',
      dropdownOneText: 'Select your business function',
      dropdownTwoText: 'Select your country of work',
      dropdownThreeText: 'Select your store/unit/workplace name',
      originText:
        'These experience question are sent out from Ingka Group, the largest IKEA retailer. Operating IKEA Retail, Ingka Centres and Ingka Investments, read more on ingka.com. Answers are used to improve the experience applying for a job with us and and the experience working in any part of the group.',
      originLogoUrl:
        'https://stingkademographicsprod.blob.core.windows.net/public/logos/011a4704-a7fb-468d-a26d-fe54d9efc5e9.png',
    },
  ];

  getSurvey(locale: string, surveyId: number): Promise<SurveyResponse> {
    let response = this.surveys.filter(
      (item) => item.locale === locale && item.surveyId === surveyId
    );

    if (response.length === 0) return Promise.reject();

    return Promise.resolve(response[0]);
  }
}
