import styled from 'styled-components';
import { BaseStyles } from '../../styles/BaseStyles/BaseStyles.styled';
import { IBaseStyles } from '../../common/types';

interface Props extends IBaseStyles {
  textAlign?: string;
  textTransform?: string;
  height?: string;
  lineHeight?: string;
  letterSpacing?: string;
}

export const Span = styled.span<Props>`
  ${BaseStyles}
  display: inline-block;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  text-transform: ${({ textTransform }) => textTransform || 'none'};
  height: ${({ height }) => height || 'auto'};
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: ${({ lineHeight }) => lineHeight};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
`;
