import { useEffect, useRef } from 'react';
import { useReduxSelector } from '../../common/hooks/hooks';
import welcome_page_image from '../../images/welcome_page_image.png';
import { HeaderContainer } from './Header.styled';
import { FlexTemplate } from '../Templates/FlexTemplate/FlexTemplate.styled';
import { BannerText } from '../BannerText/BannerText.styled';

export default function Header() {
  const survey = useReduxSelector((state) => state.survey);

  return (
    <HeaderContainer>
      <FlexTemplate justifyContent="center" alignItems="center" height="100%">
        <BannerText dangerouslySetInnerHTML={{ __html: survey.value.bannerText }} />
      </FlexTemplate>
    </HeaderContainer>
  );
}
