import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import store from './common/redux/store';
import { Provider as ProviderInversify } from 'inversify-react';
import { container } from './IoC.config';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import './font-faces.css';

import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/en';

import './fonts/notoikealatin-bold-webfont.woff';
import './fonts/notoikealatin-bold-webfont.woff2';
import './fonts/notoikealatin-bolditalic-webfont.woff';
import './fonts/notoikealatin-bolditalic-webfont.woff2';
import './fonts/notoikealatin-regular-webfont.woff';
import './fonts/notoikealatin-regular-webfont.woff2';
import './fonts/notoikealatin-italic-webfont.woff';
import './fonts/notoikealatin-italic-webfont.woff2';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <>
    <Helmet>
      <title>{process.env['REACT_APP_PAGE_TITLE']}</title>
    </Helmet>
    <ProviderInversify container={container}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ProviderInversify>
  </>
);
