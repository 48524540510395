import 'reflect-metadata';
import { injectable } from 'inversify';
import { ISurveysService, SurveyResponse } from './interfaces/ISurveysService';
import {
  IUserInterfaceTranslationsService,
  UserInterfaceTranslationResponse,
} from './interfaces/IUserInterfaceTranslationsService';

@injectable()
export class MockUserInterfaceTranslationsService implements IUserInterfaceTranslationsService {
  private surveys: UserInterfaceTranslationResponse[] = [
    {
      locale: 'en-gb',
      buttonText: 'Next',
      dropdownLanguageText: 'Language',
      dropdownOneText: 'Select your business function',
      dropdownTwoText: 'Select your country of work',
      dropdownThreeText: 'Select your store/unit/workplace',
      selectPlaceholderText: 'Select...',
    },
    {
      locale: 'pl-pl',
      buttonText: 'Dalej',
      dropdownLanguageText: 'Język',
      dropdownOneText: 'Wybierz swoją funkcję biznesową',
      dropdownTwoText: 'Wybierz kraj w którym pracujesz',
      dropdownThreeText: 'Wybierz swój sklep/jednostkę/miejsce pracy',
      selectPlaceholderText: 'Wybierz...',
    },
  ];

  getUserInterfaceTranslation(locale: string): Promise<UserInterfaceTranslationResponse> {
    let response = this.surveys.filter((item) => item.locale === locale);

    if (response.length === 0) return Promise.reject();

    return Promise.resolve(response[0]);
  }
}
