import 'reflect-metadata';
import { injectable } from 'inversify';
import { PATHS, requests } from '../utils/fetcher';
import {
    ILocaleDropdownTranslationsService,
    LocaleDropdownTranslationsResponse
} from "./interfaces/ILocaleDropdownTranslationsService";

@injectable()
export class LocaleDropdownTranslationsService implements ILocaleDropdownTranslationsService {
    getLocaleDropdownTranslationsBySurveyId(surveyId: number, locale: string): Promise<LocaleDropdownTranslationsResponse[]> {
        return requests.get(
            `${PATHS.LOCALES_DROPDOWN_TRANSLATIONS}/${locale}/${surveyId}`
        );
    }
}