import Select from 'react-select';
import { FlexTemplate } from '../Templates/FlexTemplate/FlexTemplate.styled';
import { Span } from '../Span/Span.styled';
import {
  ILocaleDropdownTranslationsService,
  LocaleDropdownTranslationsResponse,
} from '../../common/services/interfaces/ILocaleDropdownTranslationsService';
import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';
import { SelectItem } from '../../common/types';
import { useSearchParams } from 'react-router-dom';
import { useReduxDispatch, useReduxSelector } from '../../common/hooks/hooks';
import { changeLocale } from '../../common/redux/localeSlice';
import { selectStylesLanguage } from '../../styles/SelectStyles/selectStyles';
import globeIcon from '../../icons/globe.svg';

export default function Header() {
  const _localeDropdownTranslationsService: ILocaleDropdownTranslationsService = useInjection(
    ILocaleDropdownTranslationsService.$
  );
  const [localesOptions, setLocalesOptions] = useState<SelectItem[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useReduxDispatch();
  const locale = useReduxSelector((state) => state.locale);
  const userInterfaceTranslation = useReduxSelector((state) => state.userInterfaceTranslations);

  useEffect(() => {
    const surveyId = Number(searchParams.get(String(process.env['REACT_APP_ID_URL_PARAM_NAME'])));

    _localeDropdownTranslationsService
      .getLocaleDropdownTranslationsBySurveyId(surveyId, locale.value)
      .then((response) => {
        const options: SelectItem[] = response.map((item: LocaleDropdownTranslationsResponse) => ({
          value: item.toLocale,
          label: item.translation,
        }));

        setLocalesOptions(options);
      });
  }, [locale.value]);

  const handleLocaleChange = (selected: any) => {
    dispatch(changeLocale(selected.value));
  };

  return (
    <FlexTemplate justifyContent="flex-end" alignItems="center" width="100%" margin="0 0 20px 0">
      <FlexTemplate
        alignItems="center"
        gap="8px"
        padding="4px 14px 4px 24px"
        border="1px solid black"
        borderRadius="9999px"
      >
        <img src={globeIcon} />
        <Select
          options={localesOptions}
          value={{
            value: locale.value,
            label: localesOptions.filter((lo) => lo.value == locale.value)[0]?.label,
          }}
          onChange={handleLocaleChange}
          styles={selectStylesLanguage}
        />
      </FlexTemplate>
    </FlexTemplate>
  );
}
