import 'reflect-metadata';
import { injectable } from 'inversify';
import { PATHS, requests } from '../utils/fetcher';
import {
    ILocaleDropdownTranslationsService,
    LocaleDropdownTranslationsResponse
} from "./interfaces/ILocaleDropdownTranslationsService";
import {LocalesResponse} from "./interfaces/ILocalesService";

@injectable()
export class MockLocaleDropdownTranslationsService implements ILocaleDropdownTranslationsService {
    private localeDropdownTranslationsResponses: LocaleDropdownTranslationsResponse[] = [
        {
            fromLocale: "en",
            toLocale: "cs",
            translation: "Czech"
        },
        {
            fromLocale: "en",
            toLocale: "de",
            translation: "German"
        },
        {
            fromLocale: "en",
            toLocale: "da",
            translation: "Danish"
        },
        {
            fromLocale: "en",
            toLocale: "fi",
            translation: "Finnish"
        },
        {
            fromLocale: "en",
            toLocale: "fr",
            translation: "French"
        },
        {
            fromLocale: "en",
            toLocale: "fr-ca",
            translation: "Canadian French"
        },
        {
            fromLocale: "en",
            toLocale: "nl",
            translation: "Dutch"
        },
        {
            fromLocale: "en",
            toLocale: "nb",
            translation: "Norwegian Bokmål"
        },
        {
            fromLocale: "en",
            toLocale: "ro",
            translation: "Romanian"
        },
        {
            fromLocale: "en",
            toLocale: "ru",
            translation: "Russian"
        },
        {
            fromLocale: "en",
            toLocale: "uk",
            translation: "Ukrainian"
        },
        {
            fromLocale: "en",
            toLocale: "pt",
            translation: "Portuguese"
        },
        {
            fromLocale: "en",
            toLocale: "pl",
            translation: "Polish"
        },
        {
            fromLocale: "en",
            toLocale: "es",
            translation: "Spanish"
        },
        {
            fromLocale: "en",
            toLocale: "es-LA",
            translation: "Spanish (Latin)"
        },
        {
            fromLocale: "en",
            toLocale: "ja",
            translation: "Japanese"
        },
        {
            fromLocale: "en",
            toLocale: "ko",
            translation: "Korean"
        },
        {
            fromLocale: "en",
            toLocale: "zh-Hans",
            translation: "Simplified Chinese"
        },
        {
            fromLocale: "en",
            toLocale: "sl",
            translation: "Slovenian"
        },
        {
            fromLocale: "en",
            toLocale: "sk",
            translation: "Slovak"
        },
        {
            fromLocale: "en",
            toLocale: "sv",
            translation: "Swedish"
        },
        {
            fromLocale: "en",
            toLocale: "it",
            translation: "Italian"
        },
        {
            fromLocale: "en",
            toLocale: "hu",
            translation: "Hungarian"
        },
        {
            fromLocale: "en",
            toLocale: "hr",
            translation: "Croatian"
        },
        {
            fromLocale: "en",
            toLocale: "de-at",
            translation: "Austrian German"
        },
        {
            fromLocale: "en",
            toLocale: "de-ch",
            translation: "Swiss High German"
        },
        {
            fromLocale: "en",
            toLocale: "en",
            translation: "English"
        }
    ];
    
    getLocaleDropdownTranslationsBySurveyId(surveyId: number, locale: string): Promise<LocaleDropdownTranslationsResponse[]> {
        return Promise.resolve(this.localeDropdownTranslationsResponses);
    }
}