import { interfaces } from 'inversify';

export interface LocaleDropdownTranslationsResponse {
  
  fromLocale: string;
  toLocale: string;
  translation: string
}

export interface ILocaleDropdownTranslationsService {
  getLocaleDropdownTranslationsBySurveyId(surveyId: number, locale: string): Promise<LocaleDropdownTranslationsResponse[]>;
}

export namespace ILocaleDropdownTranslationsService {
  export const $: interfaces.ServiceIdentifier<ILocaleDropdownTranslationsService> = Symbol('ILocaleDropdownTranslationsService');
}
