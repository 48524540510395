import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SurveyResponse } from '../services/interfaces/ISurveysService';

interface SurveysState {
  value: SurveyResponse;
}

const initialState: SurveysState = {
  value: {
    surveyId: 0,
    name: '',
    redirectUrl: '',
    redirectParam: '',
    bannerText: '',
    greetingText: '',
    welcomeText: '',
    privacyText: '',
    status: 0,
    locale: '',
    dropdownOneText: '',
    dropdownTwoText: '',
    dropdownThreeText: '',
    originText: '',
    originLogoUrl: '',
  },
};

export const surveysSlice = createSlice({
  name: 'surveys',
  initialState,
  reducers: {
    setSurveys: (state: any, action: PayloadAction<SurveyResponse>) => {
      state.value = action.payload;
    },
  },
});

export const { setSurveys } = surveysSlice.actions;

export default surveysSlice.reducer;
