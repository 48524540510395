import styled from 'styled-components';

interface Props {
  fontSize?: string;
  minHeight?: string;
  width?: string;
  height?: string;
  margin?: string;
  justifyContent?: string;
  alignItems?: string;
  flexDirection?: string;
  flexWrap?: string;
  flexGrow?: string;
  padding?: string;
  backgroundColor?: string;
  borderRadius?: string;
  border?: string;
  gap?: string;
  children?: JSX.Element | JSX.Element[];
}

export const FlexTemplate = styled.div<Props>`
  display: flex;
  min-height: ${({ minHeight }) => minHeight || 'auto'};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  margin: ${({ margin }) => margin || '0'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'nowrap'};
  flex-grow: ${({ flexGrow }) => flexGrow || '0'};
  padding: ${({ padding }) => padding || 'none'};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border-radius: ${({ borderRadius }) => borderRadius || 'none'};
  gap: ${({ gap }) => gap || '0'};
  font-size: ${({ fontSize }) => fontSize || 'inherit'};
  border: ${({ border }) => border || 'none'};
`;
