import 'reflect-metadata';
import { injectable } from 'inversify';
import { PATHS, requests } from '../utils/fetcher';
import { ISurveysService, SurveyResponse } from './interfaces/ISurveysService';

@injectable()
export class SurveysService implements ISurveysService {
  getSurvey(locale: string, surveyId: number): Promise<SurveyResponse> {
    return requests.get(`${PATHS.SURVEYS}?locale=${locale}&surveyId=${surveyId}`);
  }
}
