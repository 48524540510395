import { interfaces } from 'inversify';

export interface StoreResponse {
  businessFunctionCountryStoreId: number;
  name: string;
}

export interface RedirectUrlResponse {
  redirectUrl: string;
}

export interface IStoresService {
  getStores(locale: string, businessFunctionCountryId: number, populationId: number): Promise<StoreResponse[]>;
  getRedirectUrl(
    businessFunctionCountryStoreId: number,
    surveyId: number,
    locale: string,
    populationId: number
  ): Promise<RedirectUrlResponse>;
}

export namespace IStoresService {
  export const $: interfaces.ServiceIdentifier<IStoresService> = Symbol('IStoresService');
}
