import { interfaces } from 'inversify';

export interface SurveyResponse {
  surveyId: number;
  name: string;
  redirectUrl: string;
  redirectParam: string;
  bannerText: string;
  greetingText: string;
  welcomeText: string;
  privacyText: string;
  status: number;
  locale: string;
  dropdownOneText: string;
  dropdownTwoText: string;
  dropdownThreeText: string;
  originText: string;
  originLogoUrl: string;
}

export interface ISurveysService {
  getSurvey(locale: string, surveyId: number): Promise<SurveyResponse>;
}

export namespace ISurveysService {
  export const $: interfaces.ServiceIdentifier<ISurveysService> = Symbol('ISurveysService');
}
