import axios from 'axios';

const isApiKeyDisabled = process.env['REACT_APP_API_KEY_DISABLED'] === 'true';
const apiKeyHeaderName = String(process.env['REACT_APP_HEADER_NAME_API_KEY']);
const apiKey = String(process.env['REACT_APP_API_KEY']);

const instance = axios.create({
  baseURL: process.env['REACT_APP_API_HOST'],
  timeout: 15000,
});

if (!isApiKeyDisabled) {
  instance.defaults.headers.common[apiKeyHeaderName] = apiKey;
}

export const PATHS = {
  BUSINESS_FUNCTIONS: '/BusinessFunctions',
  COUNTRIES: '/Countries',
  STORES: {
    BASE: '/Stores',
    REDIRECT_URL: '/Stores/RedirectUrl',
  },
  LOCALES: '/Locales',
  SURVEYS: '/Surveys',
  CODES: '/Codes',
  USER_INTERFACE_TRANSLATIONS: '/UserInterfaceTranslations', 
  LOCALES_DROPDOWN_TRANSLATIONS: "/LocaleDropdownTranslations"
};

const responseBody = (response: any) => response.data;

export const requests = {
  get: (url: string) => instance.get(url).then(responseBody),
  post: (url: string, body: any) => instance.post(url, body).then(responseBody),
  put: (url: string, body: any) => instance.put(url, body).then(responseBody),
  delete: (url: string) => instance.delete(url).then(responseBody),
};
