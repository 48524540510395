import 'reflect-metadata';
import { injectable } from 'inversify';
import { PATHS, requests } from '../utils/fetcher';
import { IStoresService, RedirectUrlResponse, StoreResponse } from './interfaces/IStoresService';

@injectable()
export class StoresService implements IStoresService {
  getStores(locale: string, businessFunctionCountryId: number, populationId: number): Promise<StoreResponse[]> {
    return requests.get(
      `${PATHS.STORES.BASE}?locale=${locale}&businessFunctionCountryId=${businessFunctionCountryId}&populationId=${populationId}`
    );
  }

  getRedirectUrl(
    businessFunctionCountryStoreId: number,
    surveyId: number,
    locale: string,
    populationId: number
  ): Promise<RedirectUrlResponse> {
    return requests.get(
      `${PATHS.STORES.REDIRECT_URL}?locale=${locale}&businessFunctionCountryStoreId=${businessFunctionCountryStoreId}&surveyId=${surveyId}&populationId=${populationId}`
    );
  }
}
