// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/notoikealatin-regular-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/notoikealatin-regular-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/notoikealatin-bold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/notoikealatin-bold-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./fonts/notoikealatin-bolditalic-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./fonts/notoikealatin-bolditalic-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./fonts/notoikealatin-italic-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./fonts/notoikealatin-italic-webfont.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'noto_ikea_latin';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'noto_ikea_latin';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2'),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'noto_ikea_latin';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2'),
    url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'noto_ikea_latin';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('woff2'),
    url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('woff');
  font-weight: normal;
  font-style: italic;
}
`, "",{"version":3,"sources":["webpack://./src/font-faces.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B;0DACkE;EAClE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,8BAA8B;EAC9B;0DAC+D;EAC/D,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,8BAA8B;EAC9B;0DACqE;EACrE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,8BAA8B;EAC9B;0DACiE;EACjE,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":["@font-face {\r\n  font-family: 'noto_ikea_latin';\r\n  src: url('./fonts/notoikealatin-regular-webfont.woff2') format('woff2'),\r\n    url('./fonts/notoikealatin-regular-webfont.woff') format('woff');\r\n  font-weight: normal;\r\n  font-style: normal;\r\n}\r\n\r\n@font-face {\r\n  font-family: 'noto_ikea_latin';\r\n  src: url('./fonts/notoikealatin-bold-webfont.woff2') format('woff2'),\r\n    url('./fonts/notoikealatin-bold-webfont.woff') format('woff');\r\n  font-weight: bold;\r\n  font-style: normal;\r\n}\r\n\r\n@font-face {\r\n  font-family: 'noto_ikea_latin';\r\n  src: url('./fonts/notoikealatin-bolditalic-webfont.woff2') format('woff2'),\r\n    url('./fonts/notoikealatin-bolditalic-webfont.woff') format('woff');\r\n  font-weight: bold;\r\n  font-style: italic;\r\n}\r\n\r\n@font-face {\r\n  font-family: 'noto_ikea_latin';\r\n  src: url('./fonts/notoikealatin-italic-webfont.woff2') format('woff2'),\r\n    url('./fonts/notoikealatin-italic-webfont.woff') format('woff');\r\n  font-weight: normal;\r\n  font-style: italic;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
