import 'reflect-metadata';
import { injectable } from 'inversify';
import {
  BusinessFunctionResponse,
  IBusinessFunctionsService,
} from './interfaces/IBusinessFunctionsService';

@injectable()
export class MockBusinessFunctionService implements IBusinessFunctionsService {
  private businessFunctionsEn: BusinessFunctionResponse[] = [
    {
      businessFunctionId: 1,
      name: 'Retail - redux',
    },
    {
      businessFunctionId: 2,
      name: 'Retail - eCommerce',
    },
    {
      businessFunctionId: 3,
      name: 'Group Function',
    },
  ];

  private businessFunctionsPl: BusinessFunctionResponse[] = [
    {
      businessFunctionId: 1,
      name: 'Sprzedaż - redux',
    },
    {
      businessFunctionId: 2,
      name: 'Sprzedaż - eCommerce',
    },
    {
      businessFunctionId: 3,
      name: 'Grupy funkcyjne',
    },
  ];

  getBusinessFunctions(locale: string): Promise<BusinessFunctionResponse[]> {
    if (locale === 'en-gb') {
      return Promise.resolve(this.businessFunctionsEn);
    } else {
      return Promise.resolve(this.businessFunctionsPl);
    }
  }
}
