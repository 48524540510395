import { configureStore } from '@reduxjs/toolkit';
import localeReducer from './localeSlice';
import surveysReducer from './surveysSlice';
import userInterfaceTranslationsReducer from './userInterfaceTranslationsSlice';

const store = configureStore({
  reducer: {
    locale: localeReducer,
    survey: surveysReducer,
    userInterfaceTranslations: userInterfaceTranslationsReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
