import styled, { createGlobalStyle } from 'styled-components';
import { theme } from '../theme';

export const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'noto_ikea_latin', sans-serif;
    font-size: ${theme.fontSize.xs};
    overflow-x: hidden;
    cursor: default;
  }

  a {
    display: inline-block;
    color: ${theme.color.brand};
    width: fit-content;
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body #root {
    width: 100vw;
    height: 100vh;
  }

  input {
    font-family: ${theme.fonts.leading};
  }

  ::-webkit-input-placeholder {
    font-family: ${theme.fonts.leading};
  }

  :-moz-placeholder {
    font-family: ${theme.fonts.leading};
  }

  ::-moz-placeholder {
    font-family: ${theme.fonts.leading};
  }

  :-ms-input-placeholder {
    font-family: ${theme.fonts.leading};
  }
`;

export const MainContainer = styled.div`
  display: block;
  padding: 25px;
  max-width: 1024px;
`;
