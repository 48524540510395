import 'reflect-metadata';
import { injectable } from 'inversify';
import { CountryResponse, ICountriesService } from './interfaces/ICountriesService';

@injectable()
export class MockCountriesFunctionService implements ICountriesService {
  private countries: CountryResponse[] = [
    {
      businessFunctionCountryId: 1,
      name: 'PL',
    },
    {
      businessFunctionCountryId: 2,
      name: 'EN',
    },
    {
      businessFunctionCountryId: 3,
      name: 'GER',
    },
  ];

  getCountries(locale: string, businessFunctionId: number): Promise<CountryResponse[]> {
    return Promise.resolve(this.countries);
  }
}
