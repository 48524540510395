import { interfaces } from 'inversify';

export interface UserInterfaceTranslationResponse {
  locale: string;
  buttonText: string;
  dropdownLanguageText: string;
  dropdownOneText: string;
  dropdownTwoText: string;
  dropdownThreeText: string;
  selectPlaceholderText: string;
}

export interface IUserInterfaceTranslationsService {
  getUserInterfaceTranslation(locale: string): Promise<UserInterfaceTranslationResponse>;
}

export namespace IUserInterfaceTranslationsService {
  export const $: interfaces.ServiceIdentifier<IUserInterfaceTranslationsService> = Symbol(
    'IUserInterfaceTranslationsService'
  );
}
