import styled from 'styled-components';

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  :not(:last-child) {
    margin-bottom: 10px;
  }
`;
