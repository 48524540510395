import 'reflect-metadata';
import { injectable } from 'inversify';
import { PATHS, requests } from '../utils/fetcher';
import { CountryResponse, ICountriesService } from './interfaces/ICountriesService';

@injectable()
export class CountriesService implements ICountriesService {
  getCountries(locale: string, businessFunctionId: number): Promise<CountryResponse[]> {
    return requests.get(
      `${PATHS.COUNTRIES}?locale=${locale}&businessFunctionId=${businessFunctionId}`
    );
  }
}
