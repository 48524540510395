import styled from 'styled-components';

export const FooterContainer = styled.footer`
  grid-area: footer;
  background-color: ${(props) => props.theme.color.grey};
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
`;
