import { MainContainer } from '../styles/GlobalStyles/GlobalStyle.styled';
import { FlexTemplate } from '../components/Templates/FlexTemplate/FlexTemplate.styled';
import { Span } from '../components/Span/Span.styled';

export default function Page404() {
  return (
    <MainContainer>
      <FlexTemplate
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Span fontWeight="900" fontSize="25vw" lineHeight="25vw">
          404
        </Span>
        <Span fontWeight="300" fontSize="2vw" textTransform="uppercase">
          Survey not found
        </Span>
      </FlexTemplate>
    </MainContainer>
  );
}
