import { ITheme } from '../common/types';

export const breakpoints = {
  largeDesktop: 1920,
  desktop: 1200,
  mobile: 768,
  tablet: 992,
};

const generateMediaQuery = (breakpoint: number, extremum = 'min'): string =>
  `screen and (${extremum}-width: ${breakpoint}px)`;

export const theme: ITheme = {
  color: {
    black: '#101010',
    blackLighter: '#666',
    brand: '#0058a3',
    brandDarker: '#004A8A',
    pink: '#FF73FF',
    turquoise: '#00A4EF',
    navy: '#091F42',
    grey: '#F5F5F5',
    greyDarker: '#DFDFDF',
    white: '#FFF',
  },
  media: {
    mobile: generateMediaQuery(breakpoints.mobile),
    tablet: generateMediaQuery(breakpoints.tablet),
    desktop: generateMediaQuery(breakpoints.desktop),
  },
  fonts: {
    leading: '"Noto IKEA Latin", DM Sans, sans-serif',
    complementive: '',
  },
  fontSize: {
    xxs: '1rem',
    xs: '1.15rem',
    ms: '1.3rem',
    s: '1.6rem',
    m: '2.3rem',
    l: '3.2rem',
    xl: '3.6rem',
    xxl: '7.2rem',
  },
};
