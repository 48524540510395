import 'reflect-metadata';
import { injectable } from 'inversify';
import { PATHS, requests } from '../utils/fetcher';
import { ISurveysService, SurveyResponse } from './interfaces/ISurveysService';
import {
  IUserInterfaceTranslationsService,
  UserInterfaceTranslationResponse,
} from './interfaces/IUserInterfaceTranslationsService';

@injectable()
export class UserInterfaceTranslationsService implements IUserInterfaceTranslationsService {
  getUserInterfaceTranslation(locale: string): Promise<UserInterfaceTranslationResponse> {
    return requests.get(`${PATHS.USER_INTERFACE_TRANSLATIONS}?locale=${locale}`);
  }
}
