import styled from 'styled-components';

export const BannerText = styled.h1`
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: 700;
  text-align: center;
  margin: 0 20px;

  @media (max-width: 1000px) {
    font-size: ${({ theme }) => theme.fontSize.m};
  }
`;
