import 'reflect-metadata';
import { injectable } from 'inversify';
import { ILocalesService, LocalesResponse } from './interfaces/ILocalesService';

@injectable()
export class MockLocalesService implements ILocalesService {
  private locales: LocalesResponse[] = [
    {
      locale: 'en-gb',
    },
    {
      locale: 'pl-pl',
    },
  ];

  getLocales(): Promise<LocalesResponse[]> {
    return Promise.resolve(this.locales);
  }

  getLocalesBySurveyId(surveyId: number): Promise<LocalesResponse[]> {
    return Promise.resolve([this.locales[0]]);
  }
}
