import 'reflect-metadata';
import { injectable } from 'inversify';
import {
  BusinessFunctionResponse,
  IBusinessFunctionsService,
} from './interfaces/IBusinessFunctionsService';
import { PATHS, requests } from '../utils/fetcher';

@injectable()
export class BusinessFunctionService implements IBusinessFunctionsService {
  getBusinessFunctions(locale: string): Promise<BusinessFunctionResponse[]> {
    return requests.get(`${PATHS.BUSINESS_FUNCTIONS}?locale=${locale}`);
  }
}
