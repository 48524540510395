import styled from 'styled-components';

export const MainTemplateContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-areas:
    'header header header'
    'main main main'
    'footer footer footer';
`;

export const Main = styled.main`
  grid-area: main;
  width: 100%;
  display: flex;
  justify-content: center;
`;
